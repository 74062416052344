<template>
  <div>
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Admin tools</li>
    </vs-breadcrumb>



    <vs-button size="medium" color="warning" icon="email" @click="sendTestDailyReports()">Send test daily reports (only staff)</vs-button>

    <vs-button class="mt-5" size="large" color="danger" icon="email" @click="resendDailyReports()">RE-send daily reports</vs-button>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Unit from "../../components/Sopotniki/units/Unit";


  export default {
    name: 'AdminTools',

    components: {
      Unit
    },

    data() {
      return {

      }
    },

    computed: {

    },

    mounted() {
      const _this = this;

    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.unitId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      sendTestDailyReports() {
        const _this = this;
        _this.$vs.loading();

        Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'daily-volunteer-report/re-send-test')
          .then((res) => {
            console.log("sending response: ", res.data.data);
            _this.$vs.loading.close();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },
      resendDailyReports() {
        const _this = this;
        _this.$vs.loading();

        Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'daily-volunteer-report/re-send')
          .then((res) => {
            console.log("sending response: ", res.data.data);
            _this.$vs.loading.close();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },
    }
  }
</script>

